.footerMain {
  text-align: center;
  padding: 30px;
  background-color: #012728;
  position: sticky;
  top: 100%;
  margin-top: 50px;
}
.footerMain p {
  margin: 0px;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 1px;
}/*# sourceMappingURL=Footer.css.map */