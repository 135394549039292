header {
    position: sticky;
    top: 0px;
    z-index: 99999;
    box-shadow: 0px 0px 50px -40px #ffffff;
    background-color: #000000;
    margin-bottom: 35px;
}

.menu-icon {
    display: none;
    position: absolute;
    top: 50%;
    right: 0;
    width: 30px;
    transform: translateY(-50%);
    z-index: 1;



    img {
        filter: invert(1);
    }

    &.active {
        &::before {
            content: "";
            position: fixed;
            top: -35px;
            left: -100vw;
            width: 200vw;
            height: calc(100vh + 0px);
            background-color: #000000;
            z-index: 0;
            opacity: 0.9;
        }
    }
}

.nav-elements ul {
    display: flex;
    justify-content: space-between;
    list-style-type: none;
    margin: 0px;
}

.nav-elements ul li:not(:last-child) {
    margin-right: 30px;
}

.nav-elements ul a {
    font-size: 16px;
    font-weight: 400;
    color: #ffffff;
    text-decoration: none;
    position: relative;
    text-transform: uppercase;
}

.nav-elements ul a:hover,
.nav-elements ul a.active {
    color: #02ffff;
}

.nav-elements ul a:hover::after {
    content: '';
    position: absolute;
    bottom: -4px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #02ffff;
}

nav.navbar {
    width: 1320px;
    max-width: calc(100% - 10vw);
    z-index: 3;
    background-color: transparent;
    justify-content: space-between;
    margin: 0 auto;
    padding: 0px;

    .logo {
        flex: 0 0 10vw;
        max-width: 140px;
        min-width: 80px;

        a {
            cursor: pointer;
            display: block;
        }

        img {
            width: 100%;
            max-width: 70px;
        }
    }

    .navbar_inner {
        width: 100%;
        position: relative;
    }

    .balancebox {
        ul {
            margin: 0px;
            padding: 0px;
            display: flex;
            align-items: center;
            list-style: none;

            li {
                margin-left: auto;
                font-weight: 400;

                strong {
                    font-weight: bold;
                    margin-right: 10px;
                }

                span {
                    background-color: transparent;
                    color: #02ffff;
                    display: block;
                    padding: 10px 15px;
                    border-radius: 50px;
                    cursor: pointer;
                }
            }
        }
    }
}


@media (max-width: 768px) {
    .nav-elements ul li:not(:last-child) {
        margin-right: 30px;
    }

    header .connectBtnMain .bg-button svg {
        max-width: 140px;
    }
}

@media (max-width: 992px) {
    .balancebox {
        flex: 0 0 calc(100% - 150px);
        max-width: calc(100% - 150px);
        margin-right: 50px;
    }

    .menu-icon {
        display: block;
        cursor: pointer;
    }

    .nav-elements {
        position: absolute;
        right: 0px;
        top: 100%;
        width: 100%;
        height: auto;
        transition: all 0.3s ease-in;
        overflow: hidden;
        max-width: 320px;
        transform: scale(0);
        transform-origin: top right;
        z-index: 99999;
    }

    .nav-elements.active {
        transform: scale(1);
    }

    .nav-elements ul {
        display: flex;
        flex-direction: column;
        padding: 0;
        margin: 0;
        background-color: #343434;
        border-radius: 5px;
        border: 1px solid #ffffff;
    }

    .nav-elements ul li {
        margin-right: 0px;
    }

    .nav-elements ul li:not(:last-child) {
        margin-right: 0px;
    }

    .nav-elements ul li a {
        padding: 15px;
        display: block;
        color: #ffffff;
        font-weight: bold;
    }
}

@media (max-width: 390px) {
    .menu-icon {
        right: 10px;
        width: 20px;
    }

    nav.navbar .balancebox ul li span {
        padding: 8px 8px;
        font-size: 14px;
    }

    nav.navbar .balancebox ul li {
        margin-left: auto;
        margin-right: auto;
        font-size: 14px;
    }

    nav.navbar .balancebox ul li strong {
        margin-right: 5px;
    }

    .publicSale .infobox h1 {
        font-size: 38px;
    }

    .balancebox {
        flex-basis: calc(100% - 120px);
        max-width: calc(100% - 120px);
        margin-right: 40px;
    }

    header {
        margin-bottom: 30px;
    }
}