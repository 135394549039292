.connectWalletToggle {
    &.modal {
        z-index: 999999;
    }

    .modal-dialog {
        width: 650px;
        max-width: calc(100% - 30px);
        margin: 0 auto;
    }

    .modal-body {
        position: relative;
        z-index: 3;
        padding: 30px 30px 20px;
    }

    .modal-content {
        position: relative;
        height: 100%;
        border: 4px solid aqua;
        padding: 4px;
        background-color: #012728;

        &::before {
            content: "";
            position: absolute;
            bottom: -6px;
            right: -4px;
            background-image: url("../../Assets/images/farming-box-left-frame.svg");
            background-repeat: no-repeat;
            width: 100%;
            height: 100%;
            background-size: contain;
            z-index: 0;
            transform: scaleX(-1);
            width: 54px;
            height: 94px;
            background-position: bottom right;
        }

        &::after {
            content: "";
            position: absolute;
            top: -10px;
            background-image: url("../../Assets/images/farming-box-right-frame.svg");
            background-repeat: no-repeat;
            width: 100%;
            height: 100%;
            background-size: contain;
            z-index: 0;
            transform: scaleX(-1);
            left: -5px;
            width: 74px;
            height: 184px;
            background-position: top left;
        }
    }

    .modal-header {
        border-color: #013839;
    }

    .modal-header .btn-close {
        filter: invert(1);
        opacity: 1;
    }

    .popupbtngrp {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        margin-left: -5px;
        margin-right: -5px;

        .btngrp {
            flex: 0 0 calc(33.33% - 10px);
            max-width: calc(33.33% - 10px);
            margin: 0px 5px 20px;
        }

        button,
        a {
            background-color: #006364;
            border: 1px solid #006364;
            color: #ffffff;
            font-size: 16px;
            padding: 20px 8px;
            border-radius: 5px;
            letter-spacing: 1px;
            line-height: 1.2;
            display: block;
            text-decoration: none;
            position: relative;
            text-align: center;
            word-wrap: break-word;

            img {
                display: block;
                margin: 0 auto 15px;
                height: 45px;
                width: auto;
                // filter: hue-rotate(320deg);
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .connectWalletToggle .modal-body {
        padding: 20px 20px 10px;
    }

    .connectWalletToggle .popupbtngrp button,
    .connectWalletToggle .popupbtngrp a {
        font-size: 14px;
        padding: 15px 6px;
        font-weight: 400;
    }
}

@media only screen and (max-width: 575px) {
    .connectWalletToggle .popupbtngrp .btngrp {
        flex: 0 0 100%;
        max-width: 100%;
        margin: 0 0 15px;
    }
}